@layer components {
  .info-section {
    &__container {
      @apply container;
    }

    &__footer {
      @apply mx-auto w-full;

      .button,
      .link-button,
      .form__button {
        @apply min-w-[300px] mx-auto text-center;

        &__content {
          @apply justify-center;
        }
      }
    }

    &__title {
      @apply font-semibold text-2xl;
    }

    &__link {
      @apply flex font-semibold gap-4 items-center no-underline py-2 text-primary text-lg tracking-[0.36px] uppercase;
      @apply hover:text-primary-dark;
      @apply focus:text-primary-dark;
    }

    &_name {
      &_cloudflare,
      &_featured-articles {
        @apply text-center;
      }

      &_cloudflare {
        .info-section {
          &__body {
            @apply gap-4 grid grid-cols-1 text-slate-600 text-xs;
          }
        }
      }

      &_columns {
        @apply text-center;
        @apply lg:py-16;

        .info-section {
          &__content {
            @apply gap-8 grid;
          }
          &__column {
            @apply gap-2 grid grid-cols-1 justify-items-center;
          }
        }

        &--is-alternate {
          @apply bg-primary text-white;
        }
      }

      &_contact-us {
        @apply bg-slate-50 leading-normal mt-8 px-6 py-16 text-center;
        @apply md:py-24;
        @apply lg:mt-16 lg:p-32;

        .info-section {
          &__title {
            @apply md:text-3xl;
            @apply lg:text-4xl;
          }

          &__byline {
            @apply leading-normal text-slate-600 text-sm;
            @apply md:text-lg;
          }

          &__content {
            @apply bg-theme-white flex flex-col items-center leading-relaxed max-w-lg min-w-80 mt-8 mx-auto p-5 shadow-sm text-sm;
            @apply md:min-w-[512px] md:mt-10 md:p-10;
            @apply lg:mt-12;
          }

          &__heading {
            @apply font-semibold text-slate-900;
            @apply md:text-lg;
          }

          &__link {
            @apply font-bold mt-2;
            @apply md:mt-3 md:text-base;
          }
        }
      }

      &_content {
        @apply text-center;

        .info-section {
          &__byline {
            @apply pt-2;
          }

          &__column {
            @apply grid gap-2 max-w-[600px];

            h3 {
              @apply text-xl;
            }

            img {
              @apply w-auto;
            }
          }

          &__content {
            @apply grid gap-8 mx-auto;
          }
        }
      }

      &_cta {
        @apply text-center;

        .info-section {
          &__body {
            @apply max-w-[600px] mx-auto;

            b {
              @apply font-semibold;
            }

            img {
              @apply mb-2 w-fit;
            }
          }

          &__footer {
            @apply max-w-400 mx-auto -mt-2 text-sm;
          }

          &__content {
            .button {
              @apply max-w-400 mx-auto;
            }
          }
        }

        &--without-image {
          .info-section {
            &__container {
              @apply gap-y-8;
            }

            &__body {
              @apply gap-8 grid max-w-[600px] mx-auto;

              h2 {
                @apply font-light text-4xl;
              }

              p {
                @apply text-xl;
              }

              b {
                @apply font-semibold;
              }
            }

            &__content {
              .button {
                @apply max-w-80 mx-auto;
              }
            }
          }
        }
      }

      &_customer-savings {
        @apply !pt-6 text-center;
        @apply lg:pt-10;

        th,
        td {
          &:nth-child(1) {
            @apply pr-1;
          }

          &:nth-child(2) {
            @apply px-1;
          }

          &:nth-child(3) {
            @apply pl-1;
          }
        }

        thead {
          @apply border-b border-b-primary-dark;

          th {
            @apply font-semibold pb-1;

            &:nth-child(n + 2) {
              @apply text-right;
            }

            &:nth-child(1) {
              @apply text-left w-[144px];
            }

            &:nth-child(2) {
              @apply px-1;
            }

            &:nth-child(3) {
              @apply pl-1;
            }
          }
        }

        tbody {
          @apply text-base;

          td {
            @apply pt-1;

            &:nth-child(1) {
              @apply text-left;
            }

            &:nth-child(n + 2) {
              @apply text-right;
            }
          }
        }

        .card {
          @apply gap-4 grid grid-cols-1 max-w-[400px] mt-[72px] mx-auto pb-4 pt-[88px] w-full;
        }

        .info-section {
          &__header {
            @apply text-center;
          }

          &__featured-image {
            @apply absolute h-36 left-[calc(50%-72px)] top-[-72px] w-36;
          }
        }
      }

      &_faqs {
        @apply text-center;

        tbody {
          tr {
            &:nth-child(odd) {
              @apply bg-neutral-100;
            }
          }

          td {
            &:nth-child(n + 2) {
              svg {
                @apply mx-auto;
              }
            }
          }
        }

        .accordion {
          &__summary {
            &__icon {
              @apply text-primary;
            }
          }
        }
      }

      &_featured-articles {
        .info-section {
          &__header {
            @apply gap-4 grid grid-cols-1 max-w-2xl mx-auto;
          }

          &__body {
            @apply gap-4 grid grid-cols-1 text-left;
            @apply lg:grid-cols-3;
          }
        }
      }

      &_featured-stats {
        .info-section {
          &__container {
            @apply lg:py-8;
          }

          &__title {
            @apply font-light text-5xl;
          }

          &__content {
            @apply grid gap-4;
          }

          &__column {
            @apply justify-items-start px-2 py-4;
          }

          &__block {
            @apply gap-2 grid;

            &__title {
              @apply flex gap-x-2 items-center text-5xl;
            }
          }
        }

        &--is-alternate {
          @apply bg-primary text-primary-background;
        }
      }

      &_grid {
        a {
          @apply info-section__link;
        }

        .info-section {
          &__container {
            @apply py-8;
          }

          &__title {
            @apply border-b border-b-neutral-200 font-semibold text-lg;
          }

          &__content {
            @apply gap-4 grid grid-cols-1;
            @apply sm:gap-0 sm:grid-cols-2;
          }

          &__column {
            @apply sm:grid sm:grid-rows-2;

            &:nth-child(1) {
              @apply sm:border-r sm:pr-4;
            }

            &:nth-child(2) {
              @apply sm:pl-4;
              @apply xl:border-r xl:pr-4;
            }

            &:last-child:nth-child(odd) {
              @apply sm:col-span-2 sm:grid-rows-1;
              @apply xl:col-span-1 xl:pl-4;

              .info-section {
                &__block {
                  @apply xl:auto-rows-min;
                }
              }
            }
          }

          &__block {
            @apply gap-4 grid py-4;

            &__title {
              @apply flex font-light gap-2 items-center text-2xl;
            }

            &__link {
              @apply link;
            }

            + .info-section__block {
              @apply border-t border-t-neutral-100;
            }
          }
        }
      }

      &_how-it-works {
        @apply text-center;

        .info-section {
          &__byline {
            @apply text-sm;
          }

          &__content-title {
            @apply font-bold text-xl;
          }

          &__content-icon {
            @apply mx-auto text-5xl text-primary;
          }
        }
      }

      &_resources {
        @apply text-center;

        .info-section {
          &__header {
            @apply gap-4 grid grid-cols-1 max-w-2xl mx-auto;
          }

          &__posts {
            @apply gap-4 grid text-left;
            @apply md:grid-cols-2;
            @apply lg:grid-cols-4;
          }
        }
      }

      &_resources-grid {
        .info-section {
          &__container {
            @apply lg:py-8;
          }

          &__header {
            @apply gap-4 grid grid-cols-1;
          }

          &__title {
            @apply border-b border-b-neutral-200 font-semibold text-lg;
          }

          &__content {
            @apply gap-4 grid grid-cols-1 text-left;
            @apply sm:gap-y-8 sm:grid-cols-2;

            div {
              &:nth-child(n + 4) {
                @apply hidden;
                @apply lg:block;
              }

              &:nth-child(4) {
                @apply sm:block;
              }
            }
          }
        }

        .post-card {
          &__cta {
            @apply info-section__link;
          }
        }
      }

      &_hero {
        @apply text-center;

        .info-section {
          &__container__column-spans {
            @apply col-span-12 lg:col-span-6 lg:col-start-4;
          }

          &__header {
            @apply gap-2.5 grid grid-cols-1 px-4;
          }

          &__featured-image {
            @apply mx-auto w-auto;
          }

          &__title {
            @apply font-normal text-3xl text-primary;
          }

          &__byline {
            @apply font-normal text-xl;
          }

          &__content {
            @apply gap-8 grid grid-cols-1 max-w-[400px] mx-auto pb-4 w-full lg:pb-8;

            &__footer {
              @apply gap-4 grid grid-cols-1 max-w-[400px] mx-auto text-xs w-full;
            }
          }
        }

        .form {
          @apply max-w-[400px] mx-auto;
        }

        &--has-background {
          @apply bg-[image:var(--x-site-hero--bg-image)] bg-cover bg-no-repeat bg-top mt-[calc(var(--x-site-header--height)*-1)] pt-[var(--x-site-header--height)];

          .info-section__container {
            @apply pb-8 pt-[232px];
          }

          .info-section__header {
            @apply lg:mb-12;
          }

          &.info-section_name_hero--has-step {
            .info-section__content {
              @apply card;
            }
          }
        }

        &--is-alternate {
          .info-section {
            &__title {
              @apply text-white;
            }

            &__byline {
              @apply text-white;
            }
          }
        }

        &--is-default {
          .info-section {
            &__title {
              @apply text-neutral-900;
            }

            &__byline {
              @apply text-neutral-900;
            }
          }
        }
      }

      &_testimonials {
        .info-section {
          &__container {
            @apply px-8;
            @apply lg:py-8;
          }

          &__content {
            @apply gap-8 grid;
            @apply sm:grid-cols-2;

            img {
              @apply w-auto;
            }
          }

          &__column {
            @apply flex gap-2 items-start;
          }
        }

        .blockquote {
          @apply gap-2 grid;

          &__content {
            @apply font-light text-2xl;
          }

          &__footer {
            @apply text-sm;
          }
        }

        &--is-alternate {
          @apply bg-primary text-white;
        }
      }
    }
  }
}

/* Note: Keep these outside `@layer` block to force Tailwind to always generate these classes */
.info-section {
  &[class*="--is-primary"] {
    @apply bg-primary text-primary-text;
  }

  &[class*="--is-primary-background"] {
    @apply bg-primary-background text-text-default;
  }

  &[class*="--is-secondary"] {
    @apply bg-secondary text-secondary-text;
  }

  &[class*="--is-secondary-background"] {
    @apply bg-secondary-background text-text-default;
  }

  &[class*="--is-accent"] {
    @apply bg-accent text-accent-text;
  }

  &[class*="--is-accent-background"] {
    @apply bg-accent-background text-text-default;
  }

  &[class*="--is-theme-neutral"] {
    @apply bg-theme-neutral text-theme-neutral-text;
  }

  &[class*="--is-theme-neutral-background"] {
    @apply bg-theme-neutral-background text-text-default;
  }

  &_name_cta,
  &_name_content {
    a {
      @apply text-theme-white;
      @apply focus:text-accent;
      @apply hover:text-accent;
    }

    .button,
    .form__button {
      @apply bg-theme-white text-text-default;
      @apply focus:bg-accent focus:text-theme-white;
      @apply hover:bg-accent hover:text-theme-white;

      .button,
      .form__button {
        &__text {
          @apply font-semibold text-inherit text-lg uppercase;
        }

        &__icons {
          @apply text-inherit;
          @apply focus:text-inherit;
          @apply hover:text-inherit;
        }
      }
    }

    &--is-accent {
      .button,
      .form__button {
        @apply focus:bg-primary;
        @apply hover:bg-primary;
      }
    }

    &[class*="-background"],
    &[class*="-transparent"],
    &[class*="-theme-neutral"] {
      .button,
      .form__button {
        @apply bg-primary text-theme-white;
        @apply focus:bg-primary-dark;
        @apply hover:bg-primary-dark;

        .button,
        .form__button {
          &__text {
            @apply font-semibold text-inherit text-lg uppercase;
          }

          &__icons {
            @apply text-inherit;
            @apply focus:text-inherit;
            @apply hover:text-inherit;
          }
        }
      }
    }
  }
}
