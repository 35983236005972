@layer components {
  .site-banner {
    @apply bg-yellow-100 z-50;

    &__container {
      @apply container;
    }

    &__content {
      @apply col-span-12 text-center py-4;

      strong {
        @apply font-semibold;
      }
    }

    &--has-landing-page-bg-image {
      @apply absolute top-[var(--x-site-header--height)] w-full;
    }
  }
}

/* Note: Keep these outside `@layer` block to force Tailwind to always generate these classes */
.site-banner {
  &[class*="--is-primary"] {
    @apply bg-primary text-primary-text;
  }

  &[class*="--is-primary-background"] {
    @apply bg-primary-background text-text-default;
  }

  &[class*="--is-primary-dark"] {
    @apply bg-primary-dark text-primary-text;
  }

  &[class*="--is-primary-light"] {
    @apply bg-primary-light text-text-default;
  }

  &[class*="--is-secondary"] {
    @apply bg-secondary text-secondary-text;
  }

  &[class*="--is-secondary-background"] {
    @apply bg-secondary-background text-text-default;
  }

  &[class*="--is-secondary-dark"] {
    @apply bg-secondary-dark text-secondary-text;
  }

  &[class*="--is-secondary-light"] {
    @apply bg-secondary-light text-text-default;
  }

  &[class*="--is-accent"] {
    @apply bg-accent text-accent-text;
  }

  &[class*="--is-accent-background"] {
    @apply bg-accent-background text-text-default;
  }

  &[class*="--is-accent-dark"] {
    @apply bg-accent-dark text-accent-text;
  }

  &[class*="--is-accent-light"] {
    @apply bg-accent-light text-text-default;
  }

  &[class*="--is-theme-neutral"] {
    @apply bg-theme-neutral text-theme-neutral-text;
  }

  &[class*="--is-theme-neutral-background"] {
    @apply bg-theme-neutral-background text-text-default;
  }

  &[class*="--is-theme-neutral-dark"] {
    @apply bg-theme-neutral-dark text-theme-neutral-text;
  }

  &[class*="--is-neutral-light"] {
    @apply bg-theme-neutral-light text-text-default;
  }
}
