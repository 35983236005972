@layer components {
  .accordion {
    @apply border-t border-t-neutral-300 pb-4 pt-2 text-left;

    &__summary {
      @apply cursor-pointer flex font-semibold gap-2 items-center relative;

      &::marker,
      &::-webkit-details-marker {
        @apply content-[""] hidden;
      }

      &__icon {
        @apply self-start text-primary;
      }
    }

    &__content {
      @apply pl-6 pt-2;
    }
  }
}
