.radio {
  input {
    appearance: none;
    @apply block;
  }

  &__label {
    @apply grid font-semibold h-16 place-content-center relative text-2xl items-center w-full;
    @apply sm:h-[72px];
  }

  &__button {
    @apply absolute border-2 cursor-pointer font-bold grid h-16  rounded-lg text-2xl w-full -z-10;
    @apply sm:h-[72px];

    &--red {
      @apply bg-red-100 border-red-500;
      @apply checked:bg-red-500 hover:bg-red-500;
    }

    &--orange {
      @apply bg-orange-100 border-orange-500;
      @apply checked:bg-orange-500 hover:bg-orange-500;
    }

    &--amber {
      @apply bg-amber-100 border-amber-500;
      @apply checked:bg-amber-500 hover:bg-amber-500;
    }

    &--yellow {
      @apply bg-yellow-100 border-yellow-400;
      @apply checked:bg-yellow-400 hover:bg-yellow-400;
    }

    &--green {
      @apply bg-green-100 border-green-500;
      @apply checked:bg-green-500 hover:bg-green-500;
    }
  }
}
