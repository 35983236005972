@layer components {
  .progress {
    @apply gap-3 grid grid-cols-1 text-center w-full;

    &__text {
      @apply text-xs;
    }

    &__bar {
      @apply bg-primary-background h-[2px] w-full;

      &__fill {
        @apply bg-primary h-full;
      }
    }
  }
}
