@layer components {
  .content {
    @apply col-span-12 flex flex-col gap-4;
    @apply lg:col-span-8 lg:col-start-3;

    h1 {
      @apply heading-1;

      + * {
        @apply mt-4;
      }
    }

    h2 {
      @apply heading-2;
    }

    h3 {
      @apply heading-3;
    }

    h4 {
      @apply heading-4;
    }

    dt {
      @apply font-semibold;
    }

    ol,
    ul {
      @apply flex flex-col gap-2 ml-8;
    }

    ol {
      @apply list-decimal;
    }

    ul {
      @apply list-disc;
    }

    &__header {
      @apply text-center;
    }

    &__body {
      @apply flex flex-col gap-4;
    }

    .fa-circle-check {
      &:before {
        @apply mr-1;

        content: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' height='16' width='16' viewBox='0 0 512 512'%3E%3C!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath fill='%2322c55e' d='M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z'/%3E%3C/svg%3E");
      }
    }

    .fa-circle-xmark {
      &:before {
        @apply mr-1;

        content: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' height='16' width='16' viewBox='0 0 512 512'%3E%3C!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath fill='%23dc2626' d='M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z'/%3E%3C/svg%3E");
      }
    }

    .pagination {
      &__menu {
        @apply gap-4 flex-row list-none ml-0;
      }
    }

    .providers-list {
      @apply font-semibold text-lg;
      @apply lg:text-xl;
    }

    .warning {
      @apply bg-yellow-50 border border-yellow-400 box-border font-semibold leading-relaxed p-5 rounded-lg text-base text-gray-900;
      @apply md:text-lg;
    }
  }
}
