@layer components {
  .link-button {
    /* TODO: determine why this is max-w-max */
    @apply max-w-max no-underline;

    &:focus,
    &:hover {
      @apply text-primary-dark;
    }

    &--is-outlined,
    &--is-solid {
      @apply p-4;
    }
  }

  .button {
    @apply max-w-[400px] p-4;
  }

  .button,
  .link-button {
    @apply flex gap-x-4 gap-y-1 items-center justify-center relative rounded-lg text-center text-primary w-full;

    & .link-button__content,
    & .button__content {
      @apply flex flex-row  gap-2 w-full whitespace-nowrap;
      & .button__icons,
      & .link-button__icons {
        @apply flex;
      }
    }

    & .button__content {
      @apply flex flex-1 gap-4 items-center justify-center whitespace-nowrap;
    }

    &.button--is-secondary,
    &.link-button--is-secondary {
      @apply text-secondary;
      &.link-button--is-solid,
      &.button--is-solid {
        @apply text-white;
        @apply focus:border-secondary-dark focus:text-white;
        @apply hover:border-secondary-dark hover:text-white;
      }

      &:not(.button--is-solid) {
        @apply focus:border-secondary-dark focus:text-secondary-dark;
        @apply hover:border-secondary-dark hover:text-secondary-dark;
      }
    }

    &.button--is-primary,
    &.link-button--is-primary {
      @apply text-primary;

      &.button--is-outlined,
      &.link-button--is-outlined {
        @apply p-4;
        @apply focus:border-primary-dark focus:text-primary-dark;
        @apply hover:border-primary-dark hover:text-primary-dark;
      }

      &.link-button--is-solid,
      &.button--is-solid {
        @apply bg-primary p-4 text-white;
        @apply focus:border-primary-dark focus:text-white;
        @apply hover:border-primary-dark hover:text-white;
      }
    }

    &.button--is-outlined,
    &.link-button--is-outlined {
      @apply bg-white border border-primary p-4;
      @apply focus:border-primary-dark focus:text-primary-dark;
      @apply hover:border-primary-dark hover:shadow-md hover:text-primary-dark;

      &.link-button--is-secondary,
      &.button--is-secondary {
        @apply border-secondary text-secondary;
        @apply focus:border-secondary-dark focus:text-secondary-dark;
        @apply hover:border-secondary-dark hover:shadow-md hover:text-secondary-dark;
      }

      &.link-button--is-primary,
      &.button--is-primary {
        @apply border-primary text-primary;
        @apply focus:border-primary-dark focus:text-primary-dark;
        @apply hover:border-primary-dark hover:shadow-md hover:text-primary-dark;
      }
    }

    &.button--is-solid,
    &.link-button--is-solid {
      @apply bg-primary shadow text-white no-underline !p-4;
      @apply active:shadow-md;
      @apply focus:bg-primary-dark focus:text-white;
      @apply hover:bg-primary-dark hover:shadow-md hover:text-white;

      &.link-button--is-secondary,
      &.button--is-secondary {
        @apply bg-secondary text-white;
        @apply focus:bg-secondary-dark focus:text-white;
        @apply hover:bg-secondary-dark hover:shadow-md hover:text-white;
      }

      &.link-button--is-primary,
      &.button--is-primary {
        @apply bg-primary;
        @apply focus:bg-primary-dark;
        @apply hover:bg-primary-dark hover:shadow-md;
      }

      .link-button__icons,
      .button__icons {
        @apply text-white;
      }
    }
  }
}
