@layer components {
  .related-posts {
    @apply col-span-12 gap-4 grid mt-24;
    @apply lg:gap-8;

    &__title {
      @apply heading-2;
    }

    &__header {
      @apply text-center;
    }

    &__content {
      @apply gap-4 grid;
      @apply md:grid-cols-2;
      @apply lg:grid-cols-4;
    }

    &__footer {
      @apply mx-auto pb-28 pt-4;

      .button,
      .link-button,
      .form__button {
        @apply min-w-[300px] text-center;

        &__content {
          @apply justify-center;
        }
      }
    }
  }
}
