:root {
  --x-site-header--height: 37px;

  @media (min-width: 640px) {
    --x-site-header--height: 53px;
  }

  @media (min-width: 1024px) {
    --x-site-header--height: 73px;
  }
}
