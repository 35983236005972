@layer components {
  .post {
    @apply col-span-12 gap-4 grid typography;
    @apply xl:col-span-8 xl:col-start-3;

    &__header {
      @apply gap-4 grid;
    }

    &__title {
      @apply heading-1 mx-auto;
      @apply md:max-w-xl;
      @apply lg:max-w-2xl;
    }

    &__content {
      @apply gap-2 grid mx-auto;
      @apply md:max-w-xl;
      @apply lg:max-w-2xl;
    }
  }
}
