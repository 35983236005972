@layer components {
  .form {
    @apply gap-4 grid grid-cols-1 justify-center w-full;

    &__fieldset {
      @apply contents;

      &__header {
        @apply relative;
      }

      &__body {
        @apply gap-4 grid grid-cols-1 justify-center w-full;
      }
    }

    &__legend {
      @apply text-center text-xl;

      .fa-circle-info {
        @apply ml-2 text-neutral-400 text-lg;
      }
    }

    &__dialog {
      &__toggle {
        @apply absolute h-full left-0 top-0 w-full;
      }
    }

    &__field {
      @apply border gap-2 grid px-4 py-2 rounded-lg;

      &:focus-within {
        @apply outline outline-2 outline-blue-600 outline-offset-2;
      }

      &__message {
        @apply -mb-2 -mx-4 rounded-b-lg px-4 py-2 text-xs;

        background-color: var(--x-form-field-message_bg-color);
      }

      &_state {
        &_error {
          --x-form-field-message_bg-color: #fee2e2;

          @apply border-red-500;
        }

        &_info {
          --x-form-field-message_bg-color: #dbeafe;
        }

        &_warning {
          --x-form-field-message_bg-color: #fef9c3;

          @apply border-yellow-400;
        }
      }
    }

    &__label {
      @apply text-xs;
    }

    &__button {
      @apply button button--is-outlined border-neutral-200 min-h-[60px] py-2 rounded-lg shadow-sm text-left text-neutral-900;

      &:focus,
      &:hover {
        @apply bg-primary;
        @apply text-white;
        i,
        svg {
          @apply text-white;
        }
      }

      &__icon {
        @apply text-primary;

        &--is-loading {
          @apply absolute right-4;
        }
      }

      &__label {
        @apply w-full;
      }

      &__text {
        @apply flex-1 gap-1 grid;
      }

      &__description {
        @apply text-xs;
      }

      &--is-outlined {
        @apply bg-theme-white border border-primary no-underline !p-4 shadow text-primary;
        @apply font-semibold mx-auto text-center text-lg uppercase;

        .form__button__icon {
          @apply text-primary;
        }

        &:hover,
        &:focus {
          @apply bg-theme-white border-primary-dark shadow-md text-primary-dark;

          .form__button__icon,
          svg {
            @apply text-primary-dark;
          }
        }
      }

      &--is-solid {
        @apply bg-primary shadow text-white no-underline !p-4;
        @apply active:shadow-md;
        @apply focus:bg-primary-dark focus:text-white;
        @apply hover:bg-primary-dark hover:shadow-md hover:text-white;
        @apply border-transparent;

        .form__button__icon {
          @apply text-white;
        }
      }

      &--handles-submit {
        @apply font-semibold mx-auto text-center text-lg uppercase;
      }
    }

    &__input {
      @apply focus:outline-none;
      @apply placeholder:text-neutral-400;
    }

    &__consents {
      @apply gap-2 grid text-xs;
    }
  }

  .checkbox-wrapper {
    @apply flex gap-2 items-center;

    .form {
      &__input {
        @apply hidden;
      }
    }

    .svg-inline--fa {
      @apply text-[18px];
    }
  }

  .select-wrapper {
    @apply cursor-pointer flex gap-4 items-center relative;

    .form {
      &__input {
        @apply appearance-none bg-transparent border-none cursor-pointer w-full z-10;

        &:invalid {
          @apply text-neutral-400;
        }
      }
    }

    .fa-chevron-down {
      @apply absolute h-4 right-0 text-neutral-300 w-4;
    }
  }
}
