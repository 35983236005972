@layer components {
  .step {
    @apply card gap-8 grid grid-cols-1 justify-center pt-16 relative w-full;

    &__headshot {
      @apply absolute left-1/2 rounded-full shadow-md -translate-x-1/2 m-0 -top-12 w-24;
    }

    &__footer {
      @apply gap-4 grid grid-cols-1 justify-center pb-4 text-xs text-center;

      svg {
        @apply h-9 mx-auto text-primary w-[75px];
      }
    }
  }

  .step-content-block {
    @apply px-4;

    div {
      @apply place-content-center;
    }

    &--top {
      @apply pb-24 pt-8;
    }

    &--bottom {
      @apply pb-8 pt-24;
    }
  }

  .step-message-block {
    @apply bg-primary-background -mt-4 p-2 text-xs;
  }
}

/* Note: Keep these outside `@layer` block to force Tailwind to always generate these classes */
.step-content-block,
.step-message-block {
  &[class*="--is-primary"] {
    @apply bg-primary text-primary-text;
  }

  &[class*="--is-primary-background"] {
    @apply bg-primary-background text-text-default;
  }

  &[class*="--is-primary-dark"] {
    @apply bg-primary-dark text-primary-text;
  }

  &[class*="--is-primary-light"] {
    @apply bg-primary-light text-text-default;
  }

  &[class*="--is-secondary"] {
    @apply bg-secondary text-secondary-text;
  }

  &[class*="--is-secondary-background"] {
    @apply bg-secondary-background text-text-default;
  }

  &[class*="--is-secondary-dark"] {
    @apply bg-secondary-dark text-secondary-text;
  }

  &[class*="--is-secondary-light"] {
    @apply bg-secondary-light text-text-default;
  }

  &[class*="--is-accent"] {
    @apply bg-accent text-accent-text;
  }

  &[class*="--is-accent-background"] {
    @apply bg-accent-background text-text-default;
  }

  &[class*="--is-accent-dark"] {
    @apply bg-accent-dark text-accent-text;
  }

  &[class*="--is-accent-light"] {
    @apply bg-accent-light text-text-default;
  }

  &[class*="--is-theme-neutral"] {
    @apply bg-theme-neutral text-theme-neutral-text;
  }

  &[class*="--is-theme-neutral-background"] {
    @apply bg-theme-neutral-background text-text-default;
  }

  &[class*="--is-theme-neutral-dark"] {
    @apply bg-theme-neutral-dark text-theme-neutral-text;
  }

  &[class*="--is-theme-neutral-light"] {
    @apply bg-theme-neutral-light text-text-default;
  }

  &[class*="--show-agent"] {
    @apply pb-36;
  }
}
