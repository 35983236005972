@layer components {
  .site-header {
    @apply relative z-50;

    &__container {
      @apply container flex h-9 justify-between items-center;
      @apply sm:h-[52px];
      @apply md:py-2.5;
      @apply lg:h-[72px] lg:py-3;
    }

    &__brand {
      @apply flex items-center;
    }

    &__logo {
      @apply flex-1 self-stretch;
      @apply lg:flex lg:items-center;

      &__link {
        @apply bg-[image:var(--x-site-header--logo--default)] block bg-contain bg-left bg-no-repeat h-full max-w-[var(--x-site-header--logo--max-width)] w-full;
      }
    }

    &__nav {
      @apply lg:relative;

      &__toggle {
        @apply flex text-primary text-xl;
        @apply focus:text-primary-dark;
        @apply hover:text-primary-dark;

        .svg-inline--fa {
          @apply h-5 w-5;
          @apply lg:h-10 lg:w-8;
        }

        .fa-bars {
          @apply text-neutral-400;
        }
      }

      &__menu {
        @apply items-center;

        &__link {
          @apply block border-b-neutral-50 py-4 text-primary text-xl;
          @apply max-lg:border-b max-lg:text-2xl max-lg:text-center max-lg:w-full;
          @apply focus:text-secondary;
          @apply hover:bg-primary hover:text-white;
          @apply lg:border-b lg:focus:bg-primary lg:text-center lg:focus:text-white lg:w-full;

          &--is-button {
            @apply max-lg:bg-primary max-lg:text-white;
            @apply hover:bg-primary-dark;
          }
        }

        &--has-depth-0 {
          @apply flex gap-4;

          > .site-header {
            &__nav {
              &__menu {
                &__item {
                  &:not(:last-child) {
                    @apply hidden;
                    @apply lg:block;
                  }

                  > .site-header {
                    &__nav {
                      &__menu {
                        &__link {
                          &--is-button {
                            @apply bg-transparent border border-primary px-4 py-2 rounded-lg text-base text-primary;
                            @apply focus:bg-primary focus:text-white;
                            @apply hover:bg-primary hover:text-white;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        &--has-depth-1 {
          @apply absolute bg-white grid right-0 rounded-b-xl shadow-md top-full w-full z-50;
          @apply lg:w-[375px];

          .site-header {
            &__nav {
              &__menu {
                &__link {
                  &--is-button {
                    @apply bg-primary border-0 font-semibold rounded-b-lg text-white;
                    @apply focus:bg-primary-dark;
                    @apply hover:bg-primary-dark;
                  }
                }
              }
            }
          }
        }

        &--is-collapsed {
          @apply hidden;
        }
      }
    }

    &--is-alternate {
      .site-header {
        &__logo {
          &__link {
            @apply bg-[image:var(--x-site-header--logo--alternate)];
          }
        }

        &__nav {
          &__menu {
            &--has-depth-0 {
              @apply flex gap-4;

              > .site-header {
                &__nav {
                  &__menu {
                    &__item {
                      > .site-header {
                        &__nav {
                          &__menu {
                            &__link {
                              &--is-button {
                                @apply border-white text-white;
                                @apply focus:bg-primary focus:border-primary focus:text-white;
                                @apply hover:bg-primary hover:border-primary hover:text-white;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
