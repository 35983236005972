@layer components {
  .accredited {
    @apply gap-4 grid justify-items-center;

    &__body {
      @apply flex gap-1 justify-center;
    }

    &__pill {
      @apply bg-primary-background font-medium px-3 py-2 rounded-full text-xs;
    }
  }
}
