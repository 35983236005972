@layer components {
  .in-progress {
    &__container {
      @apply inline-flex items-center justify-center h-[22px] w-[22px];
    }

    &__exterior {
      @apply border-[1px] border-blue-600 fill-blue-600 rounded-[50px] stroke-white stroke-[1px] h-[22px] w-[22px];
    }
    &__interior {
      @apply animate-loading opacity-0;
    }

    &__checkmark-background {
      @apply animate-loaded absolute fill-blue-600 opacity-0 h-[20px] w-[20px];
    }

    &__checkmark {
      @apply animate-loaded absolute fill-white opacity-0 h-[6px] w-[10px];
    }
  }

  .loading {
    @apply h-24 mx-auto my-4 text-primary w-24;
  }
}
