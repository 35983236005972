@layer components {
  .offer-card {
    @apply bg-white max-w-[400px] mx-auto relative rounded-lg shadow-md w-full;

    &--best-match {
      @apply border-2 border-green-500;

      .offer-card__header {
        @apply bg-green-50;
      }

      .offer-card__ribbon{
        &:after {
          @apply border-b-green-500 border-l-green-500 border-r-transparent border-t-green-500;
        }

        &__text {
          @apply bg-green-500;
        }
      }
    }

    &--pre-approved {
      .offer-card__header {
        @apply bg-secondary-background;
      }
    }

    &--pre-qualified {
      .offer-card__header {
        @apply bg-accent-background;
      }
    }

    &__header {
      @apply bg-neutral-100 flex h-9 justify-between px-4 py-2 rounded-t-lg text-neutral-500 text-right text-xs;

      &--special-offer {
        @apply justify-end;
      }
    }

    &__ribbon {
      @apply absolute top-12 left-0 z-50;

      &:after {
        @apply absolute border-b-secondary border-l-[0px] border-l-secondary border-r-[12px] border-r-transparent border-solid border-t-secondary border-y-[18px] content-[""] h-full -right-3 top-0;
      }

      &__text {
        @apply bg-secondary flex font-semibold h-9 items-center pl-2 relative text-white w-[172px];
      }
    }

    &__body {
      @apply no-underline;
    }

    &__ribbon + .offer-card__logo {
      @apply mt-20;
    }

    &__logo {
      @apply h-6 mt-8 px-4 w-auto;
    }

    &__content {
      @apply grid gap-2 grid-cols-2 p-4 text-left;

      &--special {
        @apply grid-cols-1 text-neutral-900;
      }
    }

    &__get-started {
      @apply flex font-semibold items-center pt-4 text-blue-600;
    }

    &__get-started-icon {
      @apply ml-2 w-2;
    }

    &__data {
      div {
        @apply first:font-semibold first:text-base first:text-neutral-900;
        @apply text-neutral-500 text-xs;
      }
    }

    &__footer {
      @apply bg-neutral-100 p-4 rounded-b-lg text-neutral-700 text-xs;
    }

    &__accordion {
      &__summary {
        @apply cursor-pointer flex gap-2 items-center justify-between relative;

        &::marker,
        &::-webkit-details-marker {
          @apply content-[""] hidden;
        }
        &__icon {
          @apply text-neutral-900;
        }
      }

      &__content {
        @apply pt-4;
      }
    }
  }
}
