@layer utilities {
  .link {
    @apply text-[var(--x-link-color)];
    @apply focus:text-[var(--x-link-color--focus)];
    @apply hover:text-[var(--x-link-color--hover)];
    @apply visited:text-[var(--x-link-color--visited)];

    text-decoration: var(--x-link-text-decoration);
  }
}
