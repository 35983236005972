@layer components {
  .dialog {
    @apply absolute bg-slate-900/70 flex h-screen items-center justify-center text-center top-0 w-screen z-50;

    &__body {
      @apply bg-white gap-y-4 grid max-w-[400px] mx-4 px-4 py-8 relative rounded-lg shadow-md;
    }

    &__content {
      @apply gap-x-4 grid pb-8;
    }

    &__close {
      @apply absolute flex right-2 text-neutral-900 text-xl top-2;
      @apply focus:text-neutral-900;
      @apply hover:text-neutral-900;
    }

    &__title {
      @apply font-semibold text-2xl;
    }

    .button__content {
      @apply font-semibold uppercase;
    }
  }
}
