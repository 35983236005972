/* Note: Do not enclose in @layer so that this class is always included in stylesheet */
.disclosures-table {
  @apply border border-slate-200;

  tr {
    @apply align-text-top;
  }

  tr:nth-child(even) {
    @apply bg-slate-50;
  }

  td,
  th {
    @apply p-6;
  }

  th {
    @apply text-left;
  }
}
