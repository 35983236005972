@layer components {
  .pagination {
    @apply gap-4 grid;

    &__content {
      @apply gap-4 grid;
      @apply md:grid-cols-2;
      @apply lg:grid-cols-4;
    }

    &__menu {
      @apply flex gap-4 justify-center w-full;

      &__link {
        @apply no-underline;

        &--is-active {
          @apply text-neutral-900;
        }
      }
    }
  }
}
