@layer components {
  .post-card {
    @apply bg-white flex flex-col gap-2 justify-end overflow-hidden relative rounded-lg shadow transition-shadow;
    @apply hover:cursor-pointer hover:shadow-lg;

    &__image {
      @apply h-auto m-0 w-full;
    }

    &__content {
      @apply flex flex-col gap-2 p-4;
    }

    &__title {
      @apply font-light m-0 text-2xl;
    }

    &__summary {
      @apply line-clamp-3 m-0 text-base;
    }

    &__cta {
      @apply text-lg;
    }

    &__link {
      @apply absolute h-full left-0 top-0 w-full;
    }

    &:hover {
      .post-card {
        &__link {
          @apply block;
        }

        &__content {
          /* No color declared, assume primary */
          & .button,
          & .link-button {
            &:not(.link-button--is-primary),
            &:not(.link-button--is-secondary),
            &:not(.button--is-primary),
            &:not(.button--is-secondary) {
              @apply text-primary-dark;
            }
          }
          & .button--is-secondary,
          & .link-button--is-secondary {
            &:not(.link-button--is-solid),
            &:not(.button--is-solid) {
              @apply text-secondary-dark;
            }
          }
          & .button--is-primary,
          & .link-button--is-primary {
            @apply text-white;
            &:not(.link-button--is-solid),
            &:not(.button--is-solid) {
              @apply text-primary-dark;
            }
          }

          & .button--is-outlined,
          & .link-button--is-outlined {
            @apply bg-white border border-primary-dark;

            &.link-button--is-secondary,
            &.button--is-secondary {
              @apply border-secondary-dark text-secondary-dark;
            }

            &.link-button--is-primary,
            &.button--is-primary {
              @apply border-primary-dark text-primary-dark;
            }
          }

          & .button--is-solid,
          & .link-button--is-solid,
          & .button--is-solid:not(.button--is-primary),
          & .link-button--is-solid:not(.link-button--is-primary) {
            @apply bg-primary-dark shadow text-white no-underline;

            &.link-button--is-secondary,
            &.button--is-secondary {
              @apply bg-secondary-dark text-white;
            }

            &.link-button--is-primary,
            &.button--is-primary {
              @apply bg-primary-dark text-white;
            }

            .link-button__icons,
            .button__icons {
              @apply text-white;
            }
          }
        }
      }
    }
  }
}
